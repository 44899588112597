/** @jsx jsx */
import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import "react-modal-video/css/modal-video.min.css"
import { jsx } from '@emotion/react'

import { 
  elos,
}  from '../assets/styles/ElosContent.styles'

const OrigemPage = () => {
  return (
    <Fragment>
      <section css={elos}>
        <Container>
          <Row>
            <Col lg={12} sm={12} className="text-center">
                <iframe src="https://vimeo.com/event/3885327/embed" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style={{ width: 1000, height: 500 }} />
            </Col>
          </Row>
          <Row>
            <Col lg={12} sm={12} className="text-center">
                <iframe src="https://vimeo.com/live/interaction_tools/live_event/3885327" width="1000" height="300" frameborder="0" />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default OrigemPage;

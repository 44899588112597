import React from "react"
import Footer from "@/components/footer"
import Layout from "@/components/layout"
import SobreBanner from "@/components/SobreBanner"
import ElosContent from "@/components/ConhecaOProjeto"
import HeaderOne from "@/components/header-two"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"

const ElosPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Conheça o projeto: Bertoni Elos #CampusBoulevard">
          <HeaderOne />
          <SobreBanner title="Conheça o projeto: Bertoni Elos #CampusBoulevard"/>
          <ElosContent />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default ElosPage;
